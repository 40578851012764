import { useState, useEffect } from 'react'
import debounce from 'lodash/debounce'

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Create a debounced function
    const handler = debounce(() => {
      setDebouncedValue(value)
    }, delay)

    // Call the debounced function
    handler()

    // Clean up the handler on unmount
    return () => {
      handler.cancel()
    }
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
