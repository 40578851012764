// material
import { Box, Grid, Container, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
// components
import Page from '../components/Page'

import { Egressos, Mapa, Usuarios, Questionario, AreasAtuacao } from '../components/_dashboard/app/CardsPages'
// ----------------------------------------------------------------------

export default function DashboardApp() {
  return (
    <Page title="Dashboard | Portal Meus Egressos">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Olá, bem vindo de volta!</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/egressos" replace style={{ textDecoration: 'none' }}>
              <Egressos />
            </NavLink>
          </Grid>
          {/*           <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/questionario" replace style={{ textDecoration: 'none' }}>
              <Questionario />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/mapa" replace style={{ textDecoration: 'none' }}>
              <Mapa />
            </NavLink>
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/usuarios" replace style={{ textDecoration: 'none' }}>
              <Usuarios />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink to="/app/areas-atuacao" replace style={{ textDecoration: 'none' }}>
              <AreasAtuacao />
            </NavLink>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  )
}
