import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import editFill from '@iconify/icons-eva/edit-fill'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  DialogTitle
} from '@mui/material'

import { deleteUser } from '../components/_dashboard/usuarios/requests'

export default function UpdateAndDelete({ handleOpen, dialogOpen, handleDialogClose, row, refreshTable, page }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const handleEditButton = () => {
    navigate('/app/usuarios/editar', { state: { userId: row.id, row, page }, from: '/app/usuarios' })
  }

  const handleDeleteButton = async (id) => {
    try {
      setDeleteLoading(true)
      await deleteUser(id)
      enqueueSnackbar('Usuário excluído com sucesso!', { variant: 'success' })
      handleDialogClose()
      setDeleteLoading(false)
      refreshTable((prev) => !prev)
    } catch (error) {
      handleDialogClose()
      setDeleteLoading(false)
      enqueueSnackbar('Não foi possível excluir o usuário!', { variant: 'error' })
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => handleEditButton()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpen()
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <Dialog
        BackdropProps={{
          style: {
            background: 'rgb(33 43 54 / 30%)',
            boxShadow: 'none'
          }
        }}
        PaperProps={{
          style: {
            boxShadow: 'none'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">Confirmar ação</DialogTitle>
        <DialogContent id="alert-dialog-description">
          Você tem certeza que deseja excluir este Usuário? Uma vez confirmado não será possivel desfazer esta ação.
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteLoading} onClick={handleDialogClose}>
            Cancelar
          </Button>
          <Button
            disabled={deleteLoading}
            onClick={() => {
              handleDeleteButton(row.id)
            }}
          >
            {deleteLoading ? <CircularProgress /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
