import { Icon } from '@iconify/react'
import homeIcon from '@iconify/icons-mdi/home'
import schoolIcon from '@iconify/icons-mdi/school'
import chatQuestion from '@iconify/icons-mdi/chat-question'
import mapIcon from '@iconify/icons-mdi/map'
import accountMultiple from '@iconify/icons-mdi/account-multiple'
import work from '@iconify/icons-mdi/work'

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />

const sidebarConfig = [
  {
    title: 'Início',
    path: '/app/dashboard',
    icon: getIcon(homeIcon)
  },
  {
    title: 'Egressos',
    path: '/app/egressos',
    icon: getIcon(schoolIcon)
  },
  /* {
    title: 'Questionário',
    path: '/app/questionario',
    icon: getIcon(chatQuestion)
  }, */
  /* {
    title: 'Mapa',
    path: '/mapa',
    icon: getIcon(mapIcon)
  }, */
  {
    title: 'Usuários',
    path: '/app/usuarios',
    icon: getIcon(accountMultiple)
  },
  /* {
    title: 'register',
    path: '/register',
    icon: getIcon(personAddFill)
  },
  {
    title: 'Not found',
    path: '/404',
    icon: getIcon(alertTriangleFill)
  } */
  {
    title: 'Áreas de Atuação',
    path: '/app/areas-atuacao',
    icon: getIcon(work)
  }
]

export default sidebarConfig
