import React, { useState, useEffect } from 'react'
import { Icon } from '@iconify/react'
import plusFill from '@iconify/icons-eva/plus-fill'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress
} from '@mui/material'
// components
import { useSnackbar } from 'notistack'
import Page from '../components/Page'
import Scrollbar from '../components/Scrollbar'
import { UserListHead, UserListToolbar } from '../components/_dashboard/egressos'
import Desactivate from '../utils/desactivate'
import SearchNotFound from '../components/SearchNotFound'

import { getAreasAtuacao } from '../components/_dashboard/areasAtuacao/requests'
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Id', alignRight: false },
  { id: 'name', label: 'Nome', alignRight: false }
]

const AreasDeAtuacao = () => {
  const location = useLocation()
  const [page, setPage] = useState(location?.state?.page || 0)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('id')
  const [filterName, setFilterName] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const [refresh, setRefresh] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [areaId, setAreaId] = useState(null)

  const { enqueueSnackbar } = useSnackbar()
  const handleSnackBar = (message, variant) => {
    enqueueSnackbar(message, {
      variant
    })
  }

  const [areasAtuacao, setAreasAtuacao] = useState([])

  const getAllAreasAtuacao = async () => {
    try {
      setLoading(true)
      setNotFound(false)
      const areasAtuacaoList = await getAreasAtuacao(page + 1, rowsPerPage, orderBy, order, filterName)
      setAreasAtuacao(areasAtuacaoList?.data?.data)
      setTotalPage(areasAtuacaoList?.data?.pagination?.total)
      setLoading(false)
      if (areasAtuacaoList?.data?.data?.length === 0) setNotFound(true)
    } catch (error) {
      setLoading(false)
      setAreasAtuacao([])
      if (filterName) {
        setNotFound(true)
      } else {
        handleSnackBar('Não foi possível carregar as areas de atuacão', 'error')
      }
    }
  }

  useEffect(() => {
    getAllAreasAtuacao()
  }, [page, rowsPerPage, refresh, order])

  // Search when user stops typing
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllAreasAtuacao()
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [filterName])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setNotFound(false)
    setFilterName(event.target.value)
  }

  const handleDialog = (id) => {
    setAreaId(id)
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setAreaId(null)
    setDialogOpen(false)
  }

  return (
    <Page title="Áreas de Atuação | UFV">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Áreas de Atuação
          </Typography>
          <Button variant="contained" component={RouterLink} to="criar" startIcon={<Icon icon={plusFill} />}>
            Novo
          </Button>
        </Stack>

        <Card>
          <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={areasAtuacao?.length}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {!loading &&
                    areasAtuacao?.map((row) => {
                      const { id, name } = row

                      return (
                        <TableRow key={id}>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {id}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{name}</TableCell>

                          <TableCell align="right">
                            <Desactivate
                              row={row}
                              areaId={areaId}
                              handleDialogClose={handleDialogClose}
                              dialogOpen={dialogOpen}
                              handleOpen={handleDialog}
                              refreshTable={setRefresh}
                              page={page}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                </TableBody>
                {loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
                {notFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 30, 50, 100]}
            component="div"
            count={totalPage}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage="Linhas por página:"
            labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  )
}

export default AreasDeAtuacao
