import axios from 'axios'
import Cookies from 'universal-cookie'

const hostname = 'localhost'
const apiURL = process.env.REACT_APP_API_URL || `http://${hostname}:9000/api`

const Api = axios.create({
  baseURL: apiURL
})

Api.interceptors.request.use(
  async (config) => {
    const cookies = new Cookies()
    const token = cookies.get('token')

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

Api.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8'
Api.defaults.headers.post['Access-Control-Allow-Origin'] = '*'

export default Api
