import { Autocomplete, TextField } from '@mui/material'

const ComboBox = ({
  value,
  label,
  name,
  error,
  helperText,
  loading,
  open,
  onOpen,
  onClose,
  data,
  onInputChange,
  onChange,
  ...props
}) => {
  return (
    <div className="search">
      <Autocomplete
        {...props}
        value={value || null}
        name={name}
        loadingText="Carregando..."
        options={data}
        loading={loading}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        getOptionLabel={(option) => option?.name || ''}
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={(params) => <TextField error={error} helperText={helperText} {...params} label={label} />}
      />
    </div>
  )
}

export default ComboBox
