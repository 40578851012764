import { Icon } from '@iconify/react'
import schoolIcon from '@iconify/icons-mdi/school'
// material
import { styled } from '@mui/material/styles'
import { Card, Typography } from '@mui/material'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
  color: '#FFF',
  backgroundColor: '#212B36'
}))

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: 'transparent'
}))

// ----------------------------------------------------------------------

export default function AreasAtuacao() {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={schoolIcon} width={64} height={64} color="white" />
      </IconWrapperStyle>
      <Typography variant="h3" color="white">
        Áreas de Atuação
      </Typography>
    </RootStyle>
  )
}
