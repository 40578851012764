import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import editFill from '@iconify/icons-eva/edit-fill'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'

// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  DialogTitle
} from '@mui/material'

import { desactivateAreaAtuacao } from '../components/_dashboard/areasAtuacao/requests'

export default function Desactivate({ row, areaId, handleOpen, dialogOpen, handleDialogClose, refreshTable, page }) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [desactivateLoading, setDesactivateLoading] = useState(false)

  const handleEditButton = () => {
    navigate('/app/areas-atuacao/editar', { state: { areaAtuacaoId: row?.id, row, page }, from: '/app/areas-atuacao' })
  }

  const handleDesactivateButton = async () => {
    try {
      setDesactivateLoading(true)
      await desactivateAreaAtuacao(areaId)
      enqueueSnackbar('Área de atuação excluída com sucesso!', { variant: 'success' })
      handleDialogClose()
      setDesactivateLoading(false)
      refreshTable((prev) => !prev)
    } catch (error) {
      handleDialogClose()
      setDesactivateLoading(false)
      enqueueSnackbar('Não foi possível excluir a Área de atuação!', { variant: 'error' })
    }
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => handleEditButton()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpen(row?.id)
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <Dialog
        BackdropProps={{
          style: {
            background: 'rgb(33 43 54 / 30%)',
            boxShadow: 'none'
          }
        }}
        PaperProps={{
          style: {
            boxShadow: 'none'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">Confirmar ação</DialogTitle>
        <DialogContent id="alert-dialog-description">
          Você tem certeza que deseja excluir esta Área de atuação? Uma vez confirmado não será possivel desfazer esta
          ação.
        </DialogContent>
        <DialogActions>
          <Button disabled={desactivateLoading} onClick={handleDialogClose}>
            Cancelar
          </Button>
          <Button
            disabled={desactivateLoading}
            onClick={() => {
              handleDesactivateButton()
            }}
          >
            {desactivateLoading ? <CircularProgress /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
