import { Link as RouterLink, Outlet } from 'react-router-dom'
// material
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
// components
// ----------------------------------------------------------------------
const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  position: 'absolute',
  padding: theme.spacing(1, 1, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3, 5, 0)
  }
}))

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/app/dashboard">
          <Box
            component="img"
            sx={{
              width: { sm: 70 },
              height: { sm: 80 },
              maxWidth: { sm: 400, xs: 200, md: 150 },
              maxHeight: { xs: 60, md: 200 },
              content: {
                xs: `url('/static/logo_nut_port_fundo_transparente.png')`,
                sm: `url('/static/logo_nut_port_fundo_transparente.png')`
              }
            }}
            alt="Logo"
            src="/static/logo_nut_port_fundo_transparente.png"
          />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  )
}
