import Api from '../../../../api/api'

export const createAreaAtuacao = async (data) => {
  const areaAtuacao = await Api.post(`/area_activity`, data)
  return areaAtuacao
}

export const getAreasAtuacao = async (page, limit, sort, order, term) => {
  const areasAtuacao = await Api.get(
    `/area_activity?page=${page}&limit=${limit}&sort=${sort}&order=${order}&term=${term}`
  )
  return areasAtuacao
}

export const getNotPaginatedAreasAtuacao = async (query) => {
  const areasAtuacao = await Api.get(`/areas_activity/search?query=${query}`)
  return areasAtuacao
}

export const getAreaAtuacao = async (id) => {
  const areaAtuacao = await Api.get(`/area_activity/${id}`)
  return areaAtuacao
}

export const updateAreaAtuacao = async (id, data) => {
  const areaAtuacao = await Api.put(`/area_activity/${id}`, data)
  return areaAtuacao
}

export const desactivateAreaAtuacao = async (id) => {
  const areaAtuacao = await Api.delete(`/area_activity/${id}`)
  return areaAtuacao
}
