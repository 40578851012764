import { Icon } from '@iconify/react'
import { useRef, useState } from 'react'
import editFill from '@iconify/icons-eva/edit-fill'
import { Link as RouterLink, useNavigate, Navigate } from 'react-router-dom'
import trash2Outline from '@iconify/icons-eva/trash-2-outline'
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill'
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
  CircularProgress,
  Button,
  DialogTitle
} from '@mui/material'
// ----------------------------------------------------------------------

export default function UserMoreMenu(props) {
  const navigate = useNavigate()
  const { id, handleDialogClose, handleDeleteEgresso, loading, dialogOpen, handleOpen, itemId, page } = props
  const [disabledActions, setDisabledActions] = useState(false)
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleEditButton = () => {
    navigate('/app/egressos/editar', { state: { egressoId: id, page }, replace: true })
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => handleEditButton()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleOpen(id)
          }}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>

      <Dialog
        BackdropProps={{
          style: {
            background: 'rgb(33 43 54 / 30%)',
            boxShadow: 'none'
          }
        }}
        PaperProps={{
          style: {
            boxShadow: 'none'
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle id="alert-dialog-title">Confirmar ação</DialogTitle>
        <DialogContent id="alert-dialog-description">
          Você tem certeza que deseja excluir este Egresso? Uma vez confirmado não será possivel desfazer esta ação.
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={handleDialogClose}>
            Cancelar
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setDisabledActions(true)
              handleDeleteEgresso(itemId)
            }}
          >
            {loading ? <CircularProgress /> : 'Excluir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
