import PropTypes from 'prop-types'
// material
import { Box } from '@mui/material'

// ----------------------------------------------------------------------

LogoUFV.propTypes = {
  sx: PropTypes.object
}

export default function LogoUFV({ sx }) {
  return <Box component="img" src="/static/logoUFV.png" sx={{ width: 170, ...sx }} />
}
